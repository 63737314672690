<template>
  <div>
    <vs-card>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-1/2">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
            <h1 class="w-full m-5">
              Start your personhood 360 subscription today!
            </h1>
            <h3 class="w-full m-5 text-black h1">Details</h3>
            <div class="w-full ml-5 mr-12">
              <vs-input
                label-placeholder="Full Name"
                v-model="formData.fullName"
                class="w-full"
                name="fullName"
                v-validate="'required|alpha_spaces'"
                data-vv-as="Full Name"
              />
              <span class="text-danger text-sm">{{
                errors.first("fullName")
              }}</span>
            </div>
            <div class="w-full ml-5 mr-12">
              <vs-input
                label-placeholder="Company Name"
                v-model="formData.companyName"
                class="w-full"
                name="companyName"
                v-validate="'required|alpha_spaces'"
                data-vv-as="company Name"
              />
              <span class="text-danger text-sm">{{
                errors.first("companyName")
              }}</span>
            </div>

            <div class="w-full ml-5 mr-12">
              <vs-input
                label-placeholder="Email"
                v-model="formData.email"
                class="w-full"
                name="email"
                v-validate="'required|email'"
                data-vv-as="Email"
              />
              <span class="text-danger text-sm">{{
                errors.first("email")
              }}</span>
            </div>
            <div class="w-full ml-5 mr-12">
              <vs-input
                label-placeholder="Phone"
                v-model="formData.phoneNumber"
                class="w-full"
                name="phoneNumber"
                v-validate="'required|numeric'"
                data-vv-as="Phone Number"
              />
              <span class="text-danger text-sm">{{
                errors.first("phoneNumber")
              }}</span>
            </div>

            <div class="ml-5 w-full mr-12">
              <label class="block mb-2">Country</label>
              <v-select
                :options="countryOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="countryFilter"
                class="mb-4 md:mb-0"
              />
            </div>
            <div class="ml-5 w-full mr-12">
              <label class="block mb-2">State</label>
              <v-select
                :options="stateOptions"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="stateFilter"
                class="mb-4 md:mb-0"
              />
            </div>
            <!--<div class="w-full ml-5 mr-12 mt-5">
              <div class="datepicker-wrapper hasIcon">
                <vs-icon icon="calendar_today" class="icon"></vs-icon>
                <datepicker
                  format="MMMM dd yyyy"
                  :disabled-dates="disabledDates"
                  v-model="formData.startDate"
                  placeholder="Subscription Start Date"
                  prepend-icon="event"
                  :input-class="{
                    'is-true': !errors.first('startDate') && formData.startDate,
                    'is-danger': errors.first('startDate')
                  }"
                ></datepicker>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("startDate") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Start Date"
                data-vv-validate-on="change"
                name="startDate"
                v-validate="'required'"
                v-model="formData.startDate"
              />
            </div>-->
            <!--            <h3 class="w-full m-5 text-black">Payments</h3>-->
            <div class="w-full ml-5 mt-8 mb-10">
              <vs-checkbox v-model="acceptForm" class="ml-0">
                I accept the
                <a
                  href="javascript: void(0)"
                  @click="$router.push({ name: 'terms-and-conditions' })"
                  class="underline text-black"
                  >terms and conditions</a
                >
                and
                <a
                  href="javascript: void(0)"
                  @click="$router.push({ name: 'privacy-policy' })"
                  class="underline text-black"
                  >privacy policy</a
                >
              </vs-checkbox>
              <!-- <input type="checkbox" id="checkbox" class="mr-3" v-model="acceptForm" />
              <label for="checkbox" class="text-sm">
                I accept the
                <a
                  href="javascript: void(0)"
                  @click="$router.push({ name: 'terms-and-conditions' })"
                  class="underline text-black"
                >terms and conditions</a> and
                <a
                  href="javascript: void(0)"
                  @click="$router.push({ name: 'privacy-policy' })"
                  class="underline text-black"
                >privacy policy</a>
              </label>-->
            </div>
            <vs-button
              class="w-full ml-5 mr-12 mt-5 mb-10"
              :disabled="!isFormValid"
              @click="subscribeNowHandler"
              >Subscribe now</vs-button
            >
          </vs-row>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-1/2">
          <vs-card class="package">
            <!--<h1>{{ packageType | capitalize }} plan</h1>-->
            <h1>{{ packageDetails.name | capitalize }} plan</h1>
            <div v-if="packageType === 'corporate1'">
              <ul class="opt mt-4 mb-4">
                <li
                  v-for="option in options"
                  :key="option.id"
                  class="p-2 font-medium flex items-center"
                  @click="faqFilter = option.id"
                >
                  <div class="round">
                    <input
                      type="checkbox"
                      id="checkbox"
                      disabled="true"
                      v-model="option.status"
                    />
                    <label for="checkbox"></label>
                    <span class="cursor-pointer ml-6">{{ option.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="opt mt-4 mb-4">
                <li
                  v-for="option in options"
                  :key="option.id"
                  class="p-2 font-medium flex items-center"
                  @click="faqFilter = option.id"
                >
                  <div class="round">
                    <i
                      class="material-icons"
                      :style="
                        crossIfNotIncludedStyle(option, packageDetails)
                          ? 'color: red'
                          : 'color: #74CAC2'
                      "
                    >
                      {{
                        crossIfNotIncludedStyle(option, packageDetails)
                          ? "cancel"
                          : "check_circle"
                      }}
                    </i>
                    <span class="cursor-pointer">{{ option.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              class="m-5 w-full"
            >
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                class="w-1/2"
              >
                <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
                  <p class="w-full font-bold">Billing Start date</p>
                  <p class="w-full" style="color:blue">*1st month free</p>
                </vs-row>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                class="w-1/2"
              >
                <p>{{ moment(formData.startDate).format("DD/MM/YY") }}</p>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
  data() {
    return {
      formData: {
        fullName: "",
        email: "",
        phoneNumber: "",
        startDate: new Date(),
        companyName: "",
        country: "all",
        state: "all"
      },
      packageType: "basic",
      item: {},
      amount: {},
      packageDetails: "",
      options: [],
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "Select", value: "all" },
      stateFilter: { label: "Select", value: "all" },
      acceptForm: false,
      disabledDates: { to: new Date() }
    };
  },
  components: {
    Datepicker,
    vSelect
  },

  computed: {
    isFormValid() {
      return (
        !this.errors.any() &&
        this.formData.fullName &&
        this.formData.companyName &&
        this.formData.email &&
        this.formData.phoneNumber &&
        this.formData.startDate &&
        this.formData.country != "all" &&
        this.formData.state != "all" &&
        this.acceptForm != false
      );
    }
  },
  methods: {
    ...mapActions("pages", [
      "submitContactInfo",
      "fetchInclusionList",
      "fetchPackageInfo",
      "selectPackage"
    ]),
    ...mapActions("center", ["getDirectorAllCountries"]),
    ...mapActions("group", ["getAllCountries"]),
    ...mapActions("director", ["directorSignup"]),
    moment(date) {
      return moment(date);
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    subscribeNowHandler() {
      const transaction = [
        {
          item_list: {
            items: [
              {
                name: this.packageDetails.name,
                sku: "001",
                price: this.packageDetails.price,
                currency: "AUD",
                quantity: 1
              }
            ]
          },
          amount: {
            currency: "AUD",
            total: this.packageDetails.price
          }
          // description: 'This is the payment for Personhood 360 subscription Package.',
        }
      ];
      this.$vs.loading();
      let data = {
        personalInfo: this.formData,
        transactionInfo: transaction,
        packageInfo: this.packageDetails
      };
      /*this.paypalRequest(data)*/
      this.directorSignup(data)
        .then(response => {
          //update the store with new subscription packageId

          this.$vs.notify({
            title: `You have subscript the package please check your email for password and login.`,
            text: `Your package selection completed successfully.`,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success"
          });
          this.$router.push("/login");
        })
        .catch(() => {
          this.$vs.notify({
            title: "Failure",
            text: "Unable to select your package. Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    crossIfNotIncludedStyle(feature, row) {
      if (row.hasOwnProperty("features") && row.features.length > 0) {
        return (
          !row.features.includes(feature._id.toString()) || !feature.status
        );
      }
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.formData.state = "all";
      this.formData.country = obj.value;
    },
    stateFilter(obj) {
      this.formData.state = obj.value;
    }
  },

  async created() {
    this.packageType = this.$route.params.type;
    this.fetchInclusionList().then(res => {
      this.options = res.data.data;
    });
    await this.fetchPackageInfo(this.packageType).then(res => {
      this.packageDetails = res.data.data;
    });

    await this.getDirectorAllCountries().then(res => {
      let countries = this.$store.state.center.countryOptions;
      //countries.shift();
      this.countryOptions = countries;
      //this.stateOptions  = this.countryOptions[0].states
    });
  }
};
</script>

<style lang="scss">
.package {
  margin: 100px 50px 50px 50px;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #ffff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

#package-section {
  padding: 0 40px 40px;

  .package-card {
    min-height: 400px;
    margin: 20px;
  }

  .package-card-advanced {
    min-height: 440px;
    position: relative;
    .value {
      background-color: #2898d5;
      min-height: 50px;
      padding: 10px;
      position: relative;
      text-align: center;
      border-radius: 5px;

      @media (min-width: 768px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }
    }
  }
}

.heading {
  text-align: center;
}

#contact-section {
  .contact-form-section {
    @media (min-width: 768px) {
      padding: 0 0 0 60px;
    }
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #ffff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round {
  position: relative;
  padding-left: 35px;
}
.round .material-icons {
  color: #74cac2;
  font-size: 25px;
  position: absolute;
  left: 0;
}
.last-child {
  border-left: 1px solid #a5a5a5;
}
.contact-section-wrap {
  background-color: #fff;
}
.contact-section-wrap,
.text-section {
  h1 {
    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 1;
    }
  }
  p {
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}
.vs-input--input.hasIcon:focus + .vs-input--placeholder,
.vs-con-input-label.is-label-placeholder.isFocus .vs-input--placeholder,
.vs-input--input.hasValue + .vs-placeholder-label,
.vs-input--input:focus + .vs-placeholder-label {
  padding-left: 15px !important;
}
.vs-con-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
</style>
